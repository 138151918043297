<template>
  <div class="credits">
    <div id="main-content">
      <section>
        <v-container class="small-container">
          <v-layout row wrap justify-space-around justify-center pb-5>
            <v-flex md12>
              <h1>Credits</h1>
            </v-flex>
          </v-layout>

          <v-container class="pa-5">
            <v-layout row wrap justify-space-around justify-center>
              <v-flex md4 pa-5 style="text-align: -webkit-center">
                <carousel
                  :autoplay="true"
                  :loop="true"
                  :perPageCustom="[
                    [100, 1],
                    [200, 1],
                    [300, 1],
                    [400, 1],
                    [500, 1],
                    [600, 1],
                    [768, 1],
                    [1024, 1],
                  ]"
                  :paginationEnabled="false"
                  paginationActiveColor="#982c3c"
                >
                  <slide
                    style="text-align: center; cursor: pointer; width: 100%"
                    @slide-click="openLink"
                  >
                    <v-hover v-slot="{ hover }">
                      <v-card :elevation="hover ? 16 : 2">
                        <v-img
                          contain
                          src="../assets/background_imgs/background_1.jpg"
                          style="
                            margin: auto;
                            max-width: 100%;
                            height: inherit;
                            object-fit: contain;
                          "
                        ></v-img>

                        <v-fade-transition>
                          <v-overlay
                            v-if="hover"
                            absolute
                            color="blue-grey lighten-2"
                          >
                            <i class="fas fa-external-link-alt"></i>
                          </v-overlay>
                        </v-fade-transition>
                      </v-card>
                    </v-hover>
                  </slide>

                  <slide
                    @slide-click="openLink"
                    style="text-align: center; cursor: pointer; width: 100%"
                  >
                    <v-hover v-slot="{ hover }">
                      <v-card :elevation="hover ? 16 : 2">
                        <v-img
                          contain
                          src="../assets/background_imgs/background_5.jpg"
                          style="
                            margin: auto;
                            max-width: 100%;
                            height: inherit;
                            object-fit: contain;
                          "
                        ></v-img>

                        <v-fade-transition>
                          <v-overlay
                            v-if="hover"
                            absolute
                            color="blue-grey lighten-2"
                          >
                            <i class="fas fa-external-link-alt"></i>
                          </v-overlay>
                        </v-fade-transition>
                      </v-card>
                    </v-hover>
                  </slide>
                </carousel>
              </v-flex>
              <v-flex md8 pa-5 style="align-self: center">
                <p>Nature Vectors by Vecteezy</p>
              </v-flex>
            </v-layout>

            <v-layout row wrap justify-space-around justify-center>
              <v-flex md4 pa-5 style="text-align: -webkit-center">
                <a
                  href="https://www.vecteezy.com/free-vector/hills"
                  target="blank"
                >
                  <v-hover v-slot="{ hover }">
                    <v-card :elevation="hover ? 16 : 2">
                      <v-img
                        contain
                        src="../assets/background_imgs/background_2.jpg"
                        style="cursor: pointer; border-radius: 4px"
                      ></v-img>

                      <v-fade-transition>
                        <v-overlay
                          v-if="hover"
                          absolute
                          color="blue-grey lighten-2"
                        >
                          <i class="fas fa-external-link-alt"></i>
                        </v-overlay>
                      </v-fade-transition>
                    </v-card> </v-hover
                ></a>
              </v-flex>
              <v-flex md8 pa-5 style="align-self: center">
                <p>Hills Vectors by Vecteezy</p>
              </v-flex>
            </v-layout>

            <v-layout row wrap justify-space-around justify-center>
              <v-flex md4 pa-5 style="text-align: -webkit-center">
                <a
                  href="https://www.vecteezy.com/free-vector/scenery"
                  target="blank"
                >
                  <v-hover v-slot="{ hover }">
                    <v-card :elevation="hover ? 16 : 2">
                      <v-img
                        contain
                        src="../assets/background_imgs/background_4.jpg"
                        style="cursor: pointer; border-radius: 4px"
                      ></v-img>

                      <v-fade-transition>
                        <v-overlay
                          v-if="hover"
                          absolute
                          color="blue-grey lighten-2"
                        >
                          <i class="fas fa-external-link-alt"></i>
                        </v-overlay>
                      </v-fade-transition>
                    </v-card> </v-hover
                ></a>
              </v-flex>
              <v-flex md8 pa-5 style="align-self: center">
                <p>Scenery Vectors by Vecteezy</p>
              </v-flex>
            </v-layout>

            <v-layout row wrap justify-space-around justify-center>
              <v-flex md4 pa-5 style="text-align: -webkit-center">
                <a
                  href="https://www.freepik.com/vectors/abstract"
                  target="blank"
                >
                  <v-hover v-slot="{ hover }">
                    <v-card :elevation="hover ? 16 : 2">
                      <v-img
                        contain
                        src="../assets/project_imgs/control_ui.jpg"
                        style="cursor: pointer; border-radius: 4px"
                      ></v-img>

                      <v-fade-transition>
                        <v-overlay
                          v-if="hover"
                          absolute
                          color="blue-grey lighten-2"
                        >
                          <i class="fas fa-external-link-alt"></i>
                        </v-overlay>
                      </v-fade-transition>
                    </v-card> </v-hover
                ></a>
              </v-flex>
              <v-flex md8 pa-5 style="align-self: center">
                <p>Abstract Vectors by Rawpixel</p>
              </v-flex>
            </v-layout>
          </v-container>
        </v-container>
      </section>
    </div>
          <v-footer :padless="true" color="#161616">
        <v-row justify="center" no-gutters>
          <router-link to="/" style="text-decoration: none;">  <v-btn color="white" text rounded class="my-2">Home</v-btn></router-link>
          <router-link to="/privacyPolicy" style="text-decoration: none;">  <v-btn color="white" text rounded class="my-2">Datenschutzerklärung</v-btn></router-link>
          <v-btn color="white" text rounded class="my-2" @click="scrollToTop">Credits</v-btn>
         

          <v-col class="text-center" cols="12">
            <v-card-text class="grey--text lighten-1--text">
              Copyright © Patrick Haas — {{ new Date().getFullYear() }}
            </v-card-text>
          </v-col>
        </v-row>
      </v-footer>
  </div>
</template>


<style lang="scss">
.credits {
  height: 100%;
}
</style>


<script>
export default {
  name: "Credits",
  data() {
    return {};
  },

  methods: {
    openLink() {
      window
        .open("https://www.vecteezy.com/free-vector/nature", "_blank")
        .focus();
    },
     scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
  },
};
</script>
