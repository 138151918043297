<template>
  <div class="credits">
    <div id="main-content" style="height:100%">
      <section>
        <v-container class="small-container">
          <v-layout row wrap justify-space-around justify-center pb-5>
            <v-flex md12>
              <h1>Datenschutzerklärung</h1>
            </v-flex>
          </v-layout>

          <v-container class="pa-5">
            <v-layout row wrap justify-space-around justify-center>
              <v-flex pa-5 style="align-self: center">
                <p>Diese Webseite erhebt keine nutzerbezogenen Daten. Diese verwendet neben dem YouTube-Nocookie keine
                  weiteren Cookies. Dieser "Cookie" gibt keine personenbezogenen Daten an Dritte weiter, erlaubt
                  allerdings deren Übermittlung an bestimmte Google-Server. Dies geschieht herkömmlicherweise beim Aufrufen
                  eines YouTube Videos. Gemäß Artikel 6 der DSGVO ist der Nutzer bei Einbettung von Youtube Videos
                  auf diese Tatsache hinzuweisen. </p>
              </v-flex>
            </v-layout>



          </v-container>
        </v-container>
      </section>
    </div>
    <v-footer bottom fixed padless color="#161616">
      <v-row justify="center" no-gutters>
        <router-link to="/" style="text-decoration: none;">
          <v-btn color="white" text rounded class="my-2">Home</v-btn>
        </router-link>
        <v-btn color="white" text rounded class="my-2" @click="scrollToTop">Datenschutzerklärung</v-btn>
        <router-link to="/credits" style="text-decoration: none;">
          <v-btn color="white" text rounded class="my-2">Credits</v-btn>
        </router-link>


        <v-col class="text-center" cols="12">
          <v-card-text class="grey--text lighten-1--text">
            Copyright © Patrick Haas — {{ new Date().getFullYear() }}
          </v-card-text>
        </v-col>
      </v-row>
    </v-footer>
  </div>
</template>


<style lang="scss">
.credits {
  height: 100%;
}
</style>


<script>
export default {
  name: "Privacy Policy",
  data() {
    return {};
  },

  methods: {
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
  },
};
</script>
