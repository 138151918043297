var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"credits"},[_c('div',{attrs:{"id":"main-content"}},[_c('section',[_c('v-container',{staticClass:"small-container"},[_c('v-layout',{attrs:{"row":"","wrap":"","justify-space-around":"","justify-center":"","pb-5":""}},[_c('v-flex',{attrs:{"md12":""}},[_c('h1',[_vm._v("Credits")])])],1),_c('v-container',{staticClass:"pa-5"},[_c('v-layout',{attrs:{"row":"","wrap":"","justify-space-around":"","justify-center":""}},[_c('v-flex',{staticStyle:{"text-align":"-webkit-center"},attrs:{"md4":"","pa-5":""}},[_c('carousel',{attrs:{"autoplay":true,"loop":true,"perPageCustom":[
                  [100, 1],
                  [200, 1],
                  [300, 1],
                  [400, 1],
                  [500, 1],
                  [600, 1],
                  [768, 1],
                  [1024, 1] ],"paginationEnabled":false,"paginationActiveColor":"#982c3c"}},[_c('slide',{staticStyle:{"text-align":"center","cursor":"pointer","width":"100%"},on:{"slide-click":_vm.openLink}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":hover ? 16 : 2}},[_c('v-img',{staticStyle:{"margin":"auto","max-width":"100%","height":"inherit","object-fit":"contain"},attrs:{"contain":"","src":require("../assets/background_imgs/background_1.jpg")}}),_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"absolute":"","color":"blue-grey lighten-2"}},[_c('i',{staticClass:"fas fa-external-link-alt"})]):_vm._e()],1)],1)]}}])})],1),_c('slide',{staticStyle:{"text-align":"center","cursor":"pointer","width":"100%"},on:{"slide-click":_vm.openLink}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":hover ? 16 : 2}},[_c('v-img',{staticStyle:{"margin":"auto","max-width":"100%","height":"inherit","object-fit":"contain"},attrs:{"contain":"","src":require("../assets/background_imgs/background_5.jpg")}}),_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"absolute":"","color":"blue-grey lighten-2"}},[_c('i',{staticClass:"fas fa-external-link-alt"})]):_vm._e()],1)],1)]}}])})],1)],1)],1),_c('v-flex',{staticStyle:{"align-self":"center"},attrs:{"md8":"","pa-5":""}},[_c('p',[_vm._v("Nature Vectors by Vecteezy")])])],1),_c('v-layout',{attrs:{"row":"","wrap":"","justify-space-around":"","justify-center":""}},[_c('v-flex',{staticStyle:{"text-align":"-webkit-center"},attrs:{"md4":"","pa-5":""}},[_c('a',{attrs:{"href":"https://www.vecteezy.com/free-vector/hills","target":"blank"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":hover ? 16 : 2}},[_c('v-img',{staticStyle:{"cursor":"pointer","border-radius":"4px"},attrs:{"contain":"","src":require("../assets/background_imgs/background_2.jpg")}}),_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"absolute":"","color":"blue-grey lighten-2"}},[_c('i',{staticClass:"fas fa-external-link-alt"})]):_vm._e()],1)],1)]}}])})],1)]),_c('v-flex',{staticStyle:{"align-self":"center"},attrs:{"md8":"","pa-5":""}},[_c('p',[_vm._v("Hills Vectors by Vecteezy")])])],1),_c('v-layout',{attrs:{"row":"","wrap":"","justify-space-around":"","justify-center":""}},[_c('v-flex',{staticStyle:{"text-align":"-webkit-center"},attrs:{"md4":"","pa-5":""}},[_c('a',{attrs:{"href":"https://www.vecteezy.com/free-vector/scenery","target":"blank"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":hover ? 16 : 2}},[_c('v-img',{staticStyle:{"cursor":"pointer","border-radius":"4px"},attrs:{"contain":"","src":require("../assets/background_imgs/background_4.jpg")}}),_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"absolute":"","color":"blue-grey lighten-2"}},[_c('i',{staticClass:"fas fa-external-link-alt"})]):_vm._e()],1)],1)]}}])})],1)]),_c('v-flex',{staticStyle:{"align-self":"center"},attrs:{"md8":"","pa-5":""}},[_c('p',[_vm._v("Scenery Vectors by Vecteezy")])])],1),_c('v-layout',{attrs:{"row":"","wrap":"","justify-space-around":"","justify-center":""}},[_c('v-flex',{staticStyle:{"text-align":"-webkit-center"},attrs:{"md4":"","pa-5":""}},[_c('a',{attrs:{"href":"https://www.freepik.com/vectors/abstract","target":"blank"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":hover ? 16 : 2}},[_c('v-img',{staticStyle:{"cursor":"pointer","border-radius":"4px"},attrs:{"contain":"","src":require("../assets/project_imgs/control_ui.jpg")}}),_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"absolute":"","color":"blue-grey lighten-2"}},[_c('i',{staticClass:"fas fa-external-link-alt"})]):_vm._e()],1)],1)]}}])})],1)]),_c('v-flex',{staticStyle:{"align-self":"center"},attrs:{"md8":"","pa-5":""}},[_c('p',[_vm._v("Abstract Vectors by Rawpixel")])])],1)],1)],1)],1)]),_c('v-footer',{attrs:{"padless":true,"color":"#161616"}},[_c('v-row',{attrs:{"justify":"center","no-gutters":""}},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":"/"}},[_c('v-btn',{staticClass:"my-2",attrs:{"color":"white","text":"","rounded":""}},[_vm._v("Home")])],1),_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":"/privacyPolicy"}},[_c('v-btn',{staticClass:"my-2",attrs:{"color":"white","text":"","rounded":""}},[_vm._v("Datenschutzerklärung")])],1),_c('v-btn',{staticClass:"my-2",attrs:{"color":"white","text":"","rounded":""},on:{"click":_vm.scrollToTop}},[_vm._v("Credits")]),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-card-text',{staticClass:"grey--text lighten-1--text"},[_vm._v(" Copyright © Patrick Haas — "+_vm._s(new Date().getFullYear())+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }